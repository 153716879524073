<template>
    <div>
        <b-navbar variant="light">
            <b-navbar-nav>
                <b-nav-item
                    @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                >
                    &larr; Back
                </b-nav-item>
            </b-navbar-nav>
            <b-navbar-brand
                :to="{ name: 'get_form_responses' }"
                class="m-auto"
            >
                <font-awesome-icon
                    icon="file-invoice"
                    class="mr-2"
                ></font-awesome-icon>
                View responses
            </b-navbar-brand>
        </b-navbar>
        <b-card class="my-3">
            <h6>Show</h6>
            <b-check
                v-for="(col, col_idx) in spreadsheet.fields"
                :key="'show-check-' + col_idx"
                v-model="col.show"
                inline
            >{{ col.text }}</b-check>
            
            <div
                v-if="selectedRow && selectedRow.id > 0"
            >
                <hr/>
                <h6>Text View</h6>
                <b-textarea
                    v-model="selectedColumn.text"
                    @change="markChange(selectedRow, selectedColumn)"
                ></b-textarea>
            </div>
        </b-card>
        <div class="scrollable">
            <table>
                <thead
                    class="spreadsheet-row spreadsheet-fields"
                >
                    <tr>
                        <th
                            class="show"
                        >
                            <input
                                value="all"
                                type="checkbox"
                            />
                        </th>
                        <th class="show"></th>
                        <th
                            v-for="(col, col_idx) in spreadsheet.fields"
                            :class="{ show: col.show }"
                            :key="'hcol-'+col_idx"
                        >
                            <div class="sc-tooltip">
                                {{ (col.text.length > 10) ? col.text.slice(0, 10) + "..." : col.text }}
                                <span class="tooltiptext">{{ col.text }}</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="spreadsheet-rows mt-5" v-if="spreadsheet">
                    <template
                        v-for="(row, row_idx) in spreadsheet.rows"
                    >
                        <tr
                            v-if="!row.removed"
                            :key="'row-'+row_idx"
                            :class="{ 'spreadsheet-row': true, unsaved: (row.saved == false) }"
                        >
                            <td class="show">
                                <input
                                    :value="row_idx"
                                    :style="{ paddingRight: '5px' }"
                                    type="checkbox"
                                />
                            </td>
                            <td class="show">
                                {{ row_idx + 1 }}
                                <div class="float-right">
                                    <b-spinner
                                        v-if="row.working"
                                        small
                                        variant="primary"
                                    ></b-spinner>
                                    <template v-else>
                                        <button
                                            v-if="row.saved == true"
                                            class="spreadsheet-action text-muted mr-1"
                                        >
                                            <font-awesome-icon
                                                icon="check"
                                            ></font-awesome-icon>
                                        </button>
                                        <button
                                            v-else-if="row.saved == false"
                                            class="spreadsheet-action mr-1"
                                            @click="saveRowChange(row)"
                                        >
                                            <font-awesome-icon
                                                icon="save"
                                            ></font-awesome-icon>
                                        </button>
                                        <button
                                            class="spreadsheet-action mr-1"
                                            @click="removeRow(row)"
                                        >
                                            <font-awesome-icon
                                                icon="trash-alt"
                                            ></font-awesome-icon>
                                        </button>
                                    </template>
                                </div>
                            </td>
                            <td
                                v-for="(col, col_idx) in row.columns"
                                :key="'col-'+col_idx"
                                :class="{ show: spreadsheet.fields[col_idx].show }"
                            >
                                <input       
                                    :type="spreadsheet.fields[col_idx].type"
                                    :class="['spreadsheet-col', ...col.classList]"
                                    v-model="col.text"
                                    :disabled="(col.disabled || spreadsheet.fields[col_idx].disabled)"
                                    @change="markChange(row, col)"
                                    @focus="selectedRow = row; selectedColumn = col"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { downloadFile } from '@/api/downloadFile';
export default {
    props: ["id"],
    data() {
        return {
            fullscreen: false,
            responseCount: 0,
            responses : [],
            form      : {},
            fields    : [],
            field_keys: [],
            fieldMap  : {
                "textarea"              : "string",
                "check"                 : "checkTF",
                "input": {
                    "text"              : "string",
                    "date"              : "date",
                    "datetime-local"    : "datetime",
                    "number"            : "number"
                },
                "choice": {
                    "checkbox" : "string"
                }
            },
            validInputTypes: ["button", "checkbox", "color", "date", "datetime-local", 
                              "email", "file", "hidden", "image", "month", "number", 
                              "password", "radio", "range", "reset", "search", "submit",
                               "tel", "text", "time", "url", "week", "choice"],
            spreadsheet: {
                rows : [],
                fields: []
            },
            selectedColumn: {},
            selectedRow   : {}
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        refresh() {
            this.getForm();
        },
        setFullscreen() {
            this.fullscreen = true;
            document.documentElement.requestFullscreen();
        },
        exitFullscreen() {
            this.fullscreen = false;
            if (document.fullscreenElement)
                document.exitFullscreen();
        },
        expand(row, col) {
            console.log("EXPAND", row, col);
            if (col.text.length > 10)
                col.classList.push("bg-primary w-100");
        },
        markChange(row, col) {
            row.saved = false;
            col.saved = false;
        },
        saveRowChange(row, isRetry) {
            console.log("row: ", row);
            row.working = true;
            var updatedObjData = {};
            row.columns.forEach((column, idx) => {
                if (!column.saved)
                    updatedObjData[this.field_keys[idx]] = column.text;
            });
            console.log("changed: ", updatedObjData);
            this.$api.post(
                `/api/forms/admin/save-form-response`,
                {
                    id      : row.id,
                    changed : updatedObjData
                }
            ).then(({ data }) => {
                if (data.success) row.saved = true;
                else if (!isRetry) {
                    this.$bvModal.msgBoxConfirm(data.message, {
                        title: "Error saving form response.",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "danger",
                        okTitle: "Retry",
                        cancelTitle: "Cancel",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(retry => {
                        if (retry) this.saveRowChange(row, true);
                    });
                }
                else
                    this.$bvModal.msgBoxConfirm(data.message, {
                        title: "Error saving form response.",
                        size: "sm",
                        buttonSize: "sm",
                        cancelTitle: "Cancel",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true
                    });
                row.working = false;
            });
        },
        getForm() {
            console.log("Spreadsheet:", this.spreadsheet);
            this.$api.get(
                `/api/forms/admin/${this.id}/fetch`
            ).then(({ data }) => {
                this.form   = data.form;
                this.fields = data.fields;
                this.spreadsheet.fields = this.fields.map((field) => {
                    this.field_keys.push(field.id.toString());
                    return {
                        text     : field.label,
                        type     : (this.validInputTypes.includes(field.subtype)) ? field.subtype : "text",
                        show     : true
                    };
                });
                this.spreadsheet.fields.unshift({
                    text     : "Date Submitted",
                    type     : "datetime-local",
                    disabled : true,
                    show     : true
                });
                this.spreadsheet.fields.unshift({
                    text     : "Last Name",
                    type     : "text",
                    disabled : true,
                    show     : true
                });
                this.spreadsheet.fields.unshift({
                    text     : "First Name",
                    type     : "text",
                    disabled : true,
                    show     : true
                });
                this.field_keys.unshift("date_submitted");
                this.field_keys.unshift("last_name");
                this.field_keys.unshift("first_name");
                this.spreadsheet.cols = this.spreadsheet.fields.length;
                this.getResponses();
            });
        },
        getResponses(callback) {
            delete this.spreadsheet.rows.slice(1);
            this.responseCount = 0;
            this.$api.get(
                `/api/forms/admin/${this.id}/responses`
            ).then(({ data }) => {
                this.responses = data.form_responses.map((item) => {
                    if (typeof callback == "function") callback();
                    try {
                        var response_id = item.id;
                        var response = Object.assign(Object.assign(item, item.response), item.person);
                        console.log(response, this.field_keys);
                        this.spreadsheet.rows.push(
                            {
                                saved   : null,
                                working : false,
                                removed : false,
                                id      : response_id,
                                columns : this.field_keys.map((field_key) => {
                                    return {
                                        text      : response[field_key],
                                        classList : [],
                                        saved     : true
                                    };
                                })
                            }
                        );
                        return Object.assign(Object.assign(item, item.response), item.person);
                    } catch (err) {
                        console.log("error:", err);
                    }
                    return {};
                });
                if (this.responses.length > 0) this.responseCount = 1;
            });
        },
        guessType(field) {
            const columnType = this.fieldMap[field.type];
            if (typeof columnType == "object")
                return columnType[field.subtype];
            else if (typeof columnType == "string")
                return columnType;
            return "string";
        },
        removeRow(row, isRetry) {
            this.$api.post(
                `/api/forms/admin/remove-form-response`,
                {
                    id : row.id
                }
            ).then(({ data }) => {
                if (data.success) row.removed = true;
                else if (!isRetry) {
                    this.$bvModal.msgBoxConfirm(data.message, {
                        title: "Error deleting form response.",
                        size: "sm",
                        buttonSize: "sm",
                        okVariant: "danger",
                        okTitle: "Retry",
                        cancelTitle: "Cancel",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(retry => {
                        if (retry) this.removeRow(row, true);
                    });
                }
                else
                    this.$bvModal.msgBoxConfirm(data.message, {
                        title: "Error deleting form response.",
                        size: "sm",
                        buttonSize: "sm",
                        cancelTitle: "Cancel",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true
                    });
                row.working = false;
            });
        },
        saveResponse(row) {
            row.forEach((field) => {
                console.log("SAVE:", field, field.name, field.newVal);
            });
        },
        validateField(content, oldContent, record, row) {
            console.log("content", content, oldContent, record, row);
            for (var field of this.fields)
                if (field.id.toString() == row.name)
                    for (var validator of field.validators)
                        if (!(new RegExp(validator.regex)).test(content))
                            return validator.fail_message;
            return "";
        },
        exportCSV () {
            downloadFile(
                `/api/forms/admin/export-csv/responses-${this.form.id}`,
                `${this.form.title.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.csv`
            );
        }
    }
}
</script>
<style scoped>
@import url(../../assets/css/spreadsheet-editor.css);
tr td:not(.show), tr th:not(.show) {
    display: none;
}
</style>